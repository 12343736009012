<template>
    <div class="card">
        <div class="card-body">
        <table class="table table-sm table-bordered">
            <thead class="thead-dark">
            <tr>
                <th>#</th>
                <th>Штрих-код</th>
                <th>Цех</th>
                <th>IP</th>
                <th>Порт</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
                <th colspan="6">
                    <div class="alert alert-info text-center mb-0"><i class="fas fa-spinner fa-spin"></i> Загрузка...
                    </div>
                </th>
            </tr>
            <tr v-else-if="items.length === 0">
                <th colspan="6">
                    <div class="alert alert-warning text-center mb-0">Нет данных</div>
                </th>
            </tr>
            <template v-else>
                <tr v-for="(item, index) in items">
                    <td>{{item.id}}</td>
                    <td>
                        <input :disabled="!isBeingEditable(item)" type="text" class="form-control" v-model="item.code">
                    </td>
                    <td>
                        <div class="p-2" v-if="!isBeingEditable(item)">{{item.storage ? item.storage.name : '[без цеха]'}}</div>
                        <storage-picker v-else :nullable="false" v-model="item.storage"></storage-picker>
                    </td>
                    <td>
                        <input :disabled="!isBeingEditable(item)" type="text" class="form-control" v-model="item.ip">
                    </td>
                    <td>
                        <input :disabled="!isBeingEditable(item)" type="number" class="form-control"
                               v-model="item.port">
                    </td>
                    <td>
                        <div class="btn-group">
                            <button v-if="!isBeingEditable(item)" class="btn btn-info" @click="editable = item; $forceUpdate()"><i class="fas fa-edit"></i></button>
                            <button v-else @click="save(item)" class="btn btn-success"><i class="fas fa-check"></i></button>
                            <button v-if="!item.exists" @click="items.splice(index, 1)" class="btn btn-danger"><i class="fas fa-times"></i></button>
                        </div>
                    </td>
                </tr>
            </template>
            <tr>
                <td colspan="6" class="text-center">
                    <button @click="addItem()" class="btn btn-success"><i class="fas fa-plus"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
    import {WEIGHING_SCALES_ENDPOINT} from "../../utils/endpoints";
    import StoragePicker from "../_common/StoragePicker";
    import _ from 'lodash';

    const WITH = [
        'storage'
    ];

    export default {
        name: "List",
        components: {
            StoragePicker
        },
        data() {
            return {
                loading: false,
                items: [],
                editable: null
            };
        },
        methods: {
            load() {
                this.loading = true;
                this.$http.get(WEIGHING_SCALES_ENDPOINT, {
                    params: {
                        with: WITH,
                        without_loading: true
                    }
                }).then(response => {
                    this.items = response.data.data.data.map(i => {
                        i.exists = true;
                        return i;
                    });
                }).catch(response => this.$root.responseError(response, 'Ошибка!')).finally(() => {
                    this.loading = false;
                });
            },
            assertItemInvalid(item) {
                let valid = true;

                if (!item.storage) {
                    this.$toast.error('Укажите цех');
                    velid = false;
                }

                if (!item.code) {
                    this.$toast.error('Укажите штрих-код');
                    velid = false;
                }

                if (!item.ip) {
                    this.$toast.error('Укажите ip');
                    velid = false;
                }

                if (!item.port) {
                    this.$toast.error('Укажите порт');
                    velid = false;
                }

                return valid;
            },
            save(item) {

                if (!this.assertItemInvalid(item)) {
                    return;
                }

                let method = item.exists ? 'put' : 'post';
                let url = WEIGHING_SCALES_ENDPOINT + '/' + (item.exists ? item.id : 'store');

                this.$http[method](url, {
                    id: item.exists ? item.id : null,
                    storage_id: item.storage.id,
                    ip: item.ip,
                    port: item.port,
                    code: item.code,
                }, {
                    params: {
                        with: WITH
                    }
                }).then(response => {
                    item = response.data;
                    item.exists = true;
                    this.editable = null;
                    this.$forceUpdate();
                }).catch(response => {
                    if (!!response.data) {
                        if (!!response.data.errors) {
                            Object.keys(response.data.errors).map(k => {
                                response.data.errors[k].map(m => {
                                    this.$toast.error(m);
                                });
                            })
                        } else if (!!response.data.message) {
                            this.$toast.error(response.data.message);
                        }
                    } else {
                        this.$toast.error('Ошибка!');
                    }
                })
            },
            isBeingEditable(item) {
                if (!this.editable) {
                    return false;
                }

                if (this.editable.id !== item.id) {
                    return false;
                }

                return true;
            },
            addItem() {
                let max = _.max(this.items, 'id');
                this.items.push({
                    exists: false,
                    id: max ? max.id + 1 : 1,
                    code: '',
                    ip: '',
                    port: null,
                    storage: null,
                });
                this.editable = this.items[this.items.length - 1];
            }
        },
        mounted() {
            this.load();
        },
    }
</script>

<style scoped>

</style>
